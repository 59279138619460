import React from 'react';
// eslint-disable-next-line no-unused-vars
import { Link, Redirect } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { isMobile } from 'react-device-detect';
import { isZIPValid } from "../../Client";
import { CLAIM_REGEX } from "../../Helpers";

// eslint-disable-next-line no-unused-vars
import $ from 'jquery';
// eslint-disable-next-line no-unused-vars
import StickyBanner from "../StickyBanner";

class HeroSection extends React.Component {
    state = {
        zip: "",
        query: "",
        redirect: false,
        prompt: false,
        forClaiming: CLAIM_REGEX.test(window.location.href),
    };

    // componentDidMount() {
    //     $( document ).ready( function () {
    //         console.log('loading script');
    //         var stick_nav_offset_top = $( '#alert-container' ).offset().top;
    //
    //         var stick_nav = function () {
    //             var scrollTop = $( window ).scrollTop();
    //             if ( scrollTop > stick_nav_offset_top ) {
    //                 $( '#alert-container' ).addClass( 'sticky' );
    //             } else {
    //                 $( '#alert-container' ).removeClass( 'sticky' );
    //             }
    //         }
    //
    //         stick_nav();
    //         $( window ).scroll( function () {
    //             stick_nav();
    //         } );
    //     } );
    //
    // }

    handleZIPChange = (e) => {
        this.setState({
            zip: e.target.value,
            prompt: false,
        });
    }

    handleQueryChange = (e) => {
        this.setState({
            query: e.target.value,
            prompt: false,
        });
    }

    handleFindMyLocationClick = async (event) => {
        // MOBILE TAG
        // Do not remove for now as validation is good in the home page
        // if(isMobile) {
        console.log('clicking...');

        if (this.state.forClaiming) {
            if (this.state.zip === "" && this.state.query === "") {
                this.setState({
                    prompt: true,
                });
                return;
            }
            else {
                this.setState({
                    redirect: true,
                    prompt: false,
                });

                return;
            }
        }

        if (this.state.zip !== "") {
            if (!this.state.forClaiming) {
                const validZip = await isZIPValid(this.state.zip);
                this.setState({
                    redirect: validZip,
                    prompt: !validZip,
                })
            }

            this.setState({
                redirect: true,
            });
        } else {
            this.setState({
                prompt: true,
            })
        }
        // } else {
        //     this.setState({
        //         redirect: true,
        //     });
        // }
    }

    handleEnterFindLocation = e => {
        if (e.key === "Enter") {
            this.handleFindMyLocationClick();
        }
    }

    render() {
        // eslint-disable-next-line no-unused-vars
        const mobileSticky = {
            position: 'sticky',
            top: '90px',
        }

        // eslint-disable-next-line no-unused-vars
        const desktopSticky = {
            position: 'default',
        }
        return (
            <>
                {
                    this.state.redirect &&
                    <Redirect to={{
                        pathname: this.state.forClaiming ? "/claim" : "/practice",
                        zip: this.state.zip,
                        query: this.state.query,
                        zipOnly: this.state.zip ? true : false,
                        redirectedFromHomepage: true,
                    }} />
                }
                {/*{*/}
                {/*    !this.state.forClaiming &&*/}
                {/*    // <div className="alert align-middle nalert top-nav" role="alert" style={isMobile ? mobileSticky : desktopSticky}>*/}
                {/*    //     <div className='herofl'>*/}
                {/*    //     <div className='herotx'>*/}
                {/*    //     Need to Get a COVID-19 test? Purchase an EUA At-Home, Saliva COVID-19 Test Here.*/}
                {/*    //     </div>*/}
                {/*    //     /!* {*/}
                {/*    //         isMobile &&*/}
                {/*    //         // MOBILE TAG*/}
                {/*    //         <Link to={{*/}
                {/*    //             pathname: "/practice",*/}
                {/*    //             zip: "",*/}
                {/*    //             zipOnly: false,*/}
                {/*    //             redirect: true,*/}
                {/*    //         }} className="mx-3">*/}
                {/*    //         <i className="fas"></i>*/}
                {/*    //         Learn More<i className="fas fa-arrow-right ml-3"></i>*/}
                {/*    //*/}
                {/*    //         </Link>*/}
                {/*    //*/}
                {/*    //     } *!/*/}
                {/*    //     <a href="https://testing.urgentcare.com/" target='_blank' rel='noopener noreferrer' className='mx-3 pda-1 hover-change' style={{*/}
                {/*    //         backgroundColor: '#FF4A30',*/}
                {/*    //         border: '2px solid orangered',*/}
                {/*    //     }}><i className="fas ml-0" style={{*/}
                {/*    //         fontFamily: 'sofia-pro',*/}
                {/*    //     }}>Purchase</i></a>*/}
                {/*    //     </div>*/}
                {/*    // </div>*/}
                {/*    // <div className="alert align-middle" role="alert" id="alert-container">*/}
                {/*    //     Need to get a COVID-19 test? Purchase an EUA At-Home, Saliva COVID-19 Test Here. <a*/}
                {/*    //     href="https://testing.urgentcare.com/" target="_blank"*/}
                {/*    //     className="mx-3 border-0 btn-sm btn-cta">Purchase</a>*/}
                {/*    // </div>*/}
                {/*    // <StickyBanner />*/}
                {/*}*/}
                <div className="home h-100" >
                    <div className="container">
                        <div className="row ">
                            <div className="col-12 text-center mt-5">
                                {
                                    this.state.forClaiming ?
                                        <>
                                            <h1>Claim your Urgent Care Center Listing</h1>
                                            {/*<h2 className="my-4">Search for your center in our database</h2>*/}
                                        </> :
                                        <>
                                            <h1>24/7 Healthcare Access</h1>
                                            <h2 className="my-4">Find An Urgent Care Near You</h2>
                                        </>
                                }

                            </div>
                        </div>

                        {
                            this.state.forClaiming ?
                                <div className="row">

                                    <div className="col-sm-12 col-md-10 col-lg-6 mx-auto">

                                        <div className="home-find animated fadeInUp ">

                                            <h1>Find Local Urgent Care Centers</h1>
                                            {
                                                this.state.prompt &&
                                                <p style={{
                                                    color: "red",
                                                    fontSize: "0.9rem",
                                                }}>Enter a center name or a ZIP code.</p>
                                            }
                                            <div className="row py-4 justify-content-center mg-0">
                                                <div className="form-row px-3 align-items-stretch flex-wrap">
                                                    <div className="col-12 col-md-4">
                                                        <label className="d-table">Search By Name</label>
                                                        <input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0"
                                                            placeholder=" " required="" onChange={this.handleQueryChange} />
                                                    </div>

                                                    <div className="col-12 col-md-2">
                                                        <label className="d-table">Zip Code</label>
                                                        <input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0"
                                                            placeholder=" " required="" onChange={this.handleZIPChange} />
                                                    </div>

                                                    <div className="col row-c d-flex justify-content-center zip-btn">
                                                        <button onClick={this.handleFindMyLocationClick} className="btn-cta px-0" >Find My
                                                            Location</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> :
                                <div className="row justify-content-center">
                                    <div className="col-sm-12 col-md-10 col-lg-6 col-xl-5">
                                        <div className="home-find animated fadeInUp ">
                                            {
                                                this.state.forClaiming ? <h1>Claim Your Local Urgent Care Centers</h1> :
                                                    <h1>Find Local Urgent Care Centers</h1>
                                            }
                                            {
                                                this.state.prompt &&
                                                <p style={{
                                                    color: "red",
                                                    fontSize: "0.9rem",
                                                }}>Enter a valid ZIP code.</p>
                                            }
                                            <div className="row m-0 py-3 px-4 justify-content-center zip-div">
                                                {
                                                    this.state.forClaiming &&
                                                    <div className="col-12 col-md-8 col-lg-4 pl-0 text-left">
                                                        <label>Clinic Name</label>
                                                        <input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0" placeholder=" " required=""
                                                            onChange={this.handleQueryChange} />
                                                    </div>
                                                }
                                                <div className="col-12 col-md-8 col-lg-4 p-0 text-left">
                                                
                                                    <input type="text" className="form-control mb-3 mb-lg-0 mb-xl-0" placeholder="Zip Code" required=""
                                                        onChange={this.handleZIPChange} onKeyDown={this.handleEnterFindLocation} />
                                                        
                                                </div>
                                                <div className="col-12 col-md-12 col-lg-8 px-0 row row-c justify-content-center zip-btn">
                                                    <button className="btn-cta"
                                                        onClick={this.handleFindMyLocationClick}>Find My Location</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>
            </>
        )

    }
}

export default HeroSection;