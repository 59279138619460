import React from 'react'

function MedicalCareSection() {
    return (
        <div className="section" style= {{borderBottom: "1px solid rgba(195,202,206,2)"}}>  
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-10 text-center">
                        <img src="img/ico/ucmc.svg" className="mb-4" alt='...'/>
                        <h2 className="text-center mb-4">Urgent Care Medical Care</h2>
                        <p>An Urgent Care Center is a convenient place to address a lot of different types of issues, ailments and conditions. Here are just a few of the many ways an Urgent Care Center can help you and your loved ones:</p>
                    </div>
                </div>
               
            <div className="row comp justify-content-center  my-0 my-md-5 px-0">
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/covid-19-coronavirus/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/vial-syringe-navy.png" height="53" width="auto" alt='...'/>
                        <title className="mt-4">GLP-1</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0" >
                        <a href="https://blog.urgentcare.com/symptoms-of-influenza-the-flu/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/cold-flu.svg" alt='...'/>
                        <title className="mt-4">Cold & Flu</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-strep-throat/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/strep-throat.svg" alt='...'/>
                        <title className="mt-4">Strep Throat</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-allergies/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/allergies.svg" alt='...'/>
                        <title className="mt-4">Allergies</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/symptoms-of-asthma/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/asthma.svg" alt='...'/>
                        <title className="mt-4">Asthma</title></div></a>
                    </div>
                
                    
                
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/conjunctivitis-pink-eye/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/pink-eye.svg" alt='...'/>
                        <title className="mt-4">Pink Eye</title></div></a>
                    </div>
                <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/otitis-ear-infection/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/ear-infection.svg" alt='...'/>
                        <title className="mt-4">Ear Infection</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/urinary-tract-infection-uti/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/uti.svg" alt='...'/>
                        <title className="mt-4">Urinary Tract Infection</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/sexually-transmitted-infection-or-disease-sti-std/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/std.svg" alt='...'/>
                        <title className="mt-4">Sexually Transmitted</title></div></a>
                    </div>
                    <div className="col-6 col-md-2  ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/vomiting-and-diarrhea/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/vbd.svg" alt='...'/>
                        <title className="mt-4">Vomiting & Diarrhea</title></div></a>
                    </div>
                    
                    
                
                    <div className="col-6 col-md-2  ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/sprains-and-strains/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/ssb.svg" alt='...'/>
                        <title className="mt-4">Sprains, Strains & Breaks</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <a href="https://blog.urgentcare.com/laceration-cut-of-the-skin-and-soft-tissue/" target="_blank" rel="noopener noreferrer"><div><img src="img/ico/ls.svg" alt='...'/>
                        <title className="mt-4">Laceration & Stitches</title></div></a>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico/ds.svg" alt='...'/>
                        <title className="mt-4">Drug Screening</title>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico/ef.svg" alt='...'/>
                        <title className="mt-4">Employment Forms</title>
                    </div>
                    <div className="col-6 col-md-2 ucmc text-center my-4 px-0">
                        <img src="img/ico/sf.svg" alt='...'/>
                        <title className="mt-4">School Forms</title>
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MedicalCareSection
