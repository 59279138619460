import React from 'react';

class StickyBanner extends React.Component {

  

    render() {
        return (
            <div className="alert" role="alert" id="alert-container">
            
           
            <span className="message">Struggling to find Ozempic, Wegovy, Mounjaro, or Zepbound? <span>Get Compounded Semaglutide and Compounded Tirzepatide delivered to your home.</span> </span>
            <div className="alert-action-btn">
                <a href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=sticky_banner" target="_blank" rel="noopener noreferrer" className="btn-sm purchase-btn">See&nbsp;If&nbsp;You&nbsp;Qualify</a>   
            </div>
            </div>
        )
    }
}

export default StickyBanner;