const DAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September",
    "October", "November", "December"];

const IMAGE_FILES = ['/img/result-img-01.png', '/img/result-img-02.png', '/img/result-img-03.png', '/img/result-img-04.jpeg', '/img/result-img-05.jpeg', '/img/result-img-06.jpeg'];


// const COVID_TESTS = {
//     nasalSwab: {
//         description: "Nasal Swab",
//         img: "/img/ico/ns.svg",
//         dbEntry: "Nasal Swab",
//     },
//     blood: {
//         description: "Blood Draw",
//         img: "/img/ico/bd.svg",
//         dbEntry: "Nasal Swab",
//     }
//     finger: {
//         description: "Finger Prick",
//         img: ""
//     }
// }


const get12HourFormatTime = (hours, minutes) => {
    return (`${hours % 12}:${minutes < 10 && minutes >= 0 ? "0" + minutes.toString() : minutes} ${hours > 12 ? "pm" : "am"}`);
}

const getRandomImageFilename = () => {
    const imageIndex = Math.floor((Math.random() * IMAGE_FILES.length))
    // console.log(imageIndex);
    return IMAGE_FILES[imageIndex];
}

const parseTimeString = (time) => {
    const time_split = time.split(":");
    return {
        hours: time_split[0],
        minutes: time_split[1],
        seconds: time_split[2],
    }
}

const getTimeDecimalEquivalent = (time) => {
    const time_split = parseTimeString(time);
    return (parseInt(time_split.hours) + parseInt(time_split.minutes) / 60);
}

const get12HourFormatTimeString = (decimalTime) => {
    let hours = Math.floor(decimalTime);
    let minutes = Math.floor((decimalTime % 1) * 60);
    const hourString = ((hours % 12) === 0 ? 12 : hours % 12).toString();

    return `${hourString}:${minutes.toString().padStart(2, "0")} ` +
           `${ hours > 12 && hours < 24 ? "PM" : "AM"}`;
}

const isStillOpenV2 = (opening, closing) => {
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    const currentTime = Number(currentHour) + (Number(currentMinutes) / 60);

    if(currentTime < opening && currentTime < closing) {
        const openingIn = Math.ceil(opening - currentTime);
        return {status: "Open today",
            message: `Opens in ${openingIn} hour${openingIn > 1 ? "s":""}`};
    } else if (currentTime >= opening && currentTime < closing) {
        const closingIn = Math.ceil(closing - currentTime)
        return {status: "Open now",
            message: `Closes in ${closingIn} hour${closingIn > 1 ? "s":""}`};
    } else {
        return {status: "Closed",
            message: ""}
    }
}

const isStillOpen = (hours) => {
    // returns status, hours till closing
    if (!hours) return {status: "Closed", message: ""}
    const now = new Date();
    const currentHour = now.getHours();
    const currentMinutes = now.getMinutes();

    const currentTime = Number(currentHour) + (Number(currentMinutes) / 60);
    const openingTime = hours.opening;
    const closingTime = hours.closing;

    if(currentTime < openingTime && currentTime < closingTime) {
        const openingIn = Math.ceil(openingTime - currentTime);
        return {status: "Open today",
            message: `Opens in ${openingIn} hour${openingIn > 1 ? "s":""}`};
    } else if (currentTime >= openingTime && currentTime < closingTime) {
        const closingIn = Math.ceil(closingTime - currentTime)
        return {status: "Open now",
            message: `Closes in ${closingIn} hour${closingIn > 1 ? "s":""}`};
    } else {
        return {status: "Closed",
            message: ""}
    }
}

const isOpenToday = (schedule) => {
    const today = DAYS[(new Date()).getDay()];
    const day = schedule[today];

    return {
        status: day ? 'Open today' : 'Closed',
    }
}

const isOpenTodayV2 = (schedules) => {
    const today = DAYS[(new Date()).getDay()];
    const isOpenToday = schedules.find((s) => s.occurrence.name === today);
    const message = isOpenToday ? "Open today" : "No schedule information";
    console.log('A - schedule message');
    console.log(message);
    return {
        status: message,
    }
}

const CLAIM_REGEX = /claim=True/;



export {get12HourFormatTime, DAYS, MONTHS, getRandomImageFilename, parseTimeString,
        getTimeDecimalEquivalent, get12HourFormatTimeString, isStillOpen, isOpenToday, isOpenTodayV2, isStillOpenV2, CLAIM_REGEX};