import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import {
    getCurrentPositionUsingNativeGeolocator, getPracticeV3,
    getTimeByLocation
} from "../../Client";
import { CLAIM_REGEX, getRandomImageFilename } from "../../Helpers";
// import {getCurrentPositionUsingGoogleMapsAPI, getPractice, getTimeByLocation} from "../../Client";
import DateTodaySection from "../results/DateTodaySection";
import StickyGeolocationMessage from "../StickyGeolocationMessage";
import Insurance from "./Insurance";
import ModalSMS from "./ModalSMS";
import ScheduleTable from "./ScheduleTable";
import ServicesOffered from "./ServicesOffered";
import SingleLocationMapSection from "./SingleLocationMapSection";

class ListingPage extends React.Component {
    state = {
        practice: {
            practice_id: "",
            longitude: 121,
            latitude: 15,
            name: "",
            address: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
            email: "",
            about: "",
            services: "",
            website: "",
            xray: false,
            insurance: "",
            schedules: [],
            covid19TestServices: [],
            antibodyTestServices: [],
            partners: []
        },
        userLocation: {
            lat: 15,
            lng: 121
        },
        practiceUTCOffset: 0,
        userUTCOffset: 0,
        practiceDSTUTCOffset: 0,
        userDSTUTCOffset: 0,
        redirect: false,
        isGeolocationDenied: false,
    }

    async componentDidMount() {


        let id = null;

        if(this.props.campaign) {
            console.log(this.props.campaign);
            if(this.props.campaign.error) {
                this.setState({
                    redirect: true,
                });

                return;
            }

            this.setState({
                practice: this.props.campaign.locations[0],
            }, ()=>console.log(this.state.practice));

            const userLocationResults = await getCurrentPositionUsingNativeGeolocator();
            if(userLocationResults.error) {
                if(userLocationResults.error.code === 1) {
                    this.setState({
                        isGeolocationDenied: true,
                    })
                }
                return;
            }

            this.setState({
                userLocation: {
                    lat: userLocationResults.location.lat,
                    lng: userLocationResults.location.lng,
                }
            }, ()=>console.log(this.state));

            // compute offsets
            await this.computePracticeOffsets();
            await this.computeUserOffsets();

            return;
        }



        id = this.props.match ? this.props.match.params.slug.split("-")[0]: null;

        if(this.props.preview) {
            if(!this.props.practice) {
                this.setState({
                    redirect: true,
                });
                return;
            }

            this.setState({
                practice: this.props.practice,
            }, ()=>console.log(this.state.practice));

            return;
        }

        // const getPracticeResults = await getPracticeV2(id);
        const getPracticeResults = await getPracticeV3(id);
        if(getPracticeResults.error) {
            this.setState({
                redirect: true,
            });

            return;
        }

        this.setState({
            practice: getPracticeResults,
        }, ()=>console.log(this.state.practice));


        const userLocationResults = await getCurrentPositionUsingNativeGeolocator();
        if(userLocationResults.error) {
            if(userLocationResults.error.code === 1) {
                this.setState({
                    isGeolocationDenied: true,
                })
            }
            return;
        }

        this.setState({
            userLocation: {
                lat: userLocationResults.location.lat,
                lng: userLocationResults.location.lng,
            }
        }, ()=>console.log(this.state));

        // compute offsets
        await this.computePracticeOffsets();
        await this.computeUserOffsets();
    }

    computeUserOffsets = async () => {
        const today = Date.now() / 1000;
        const userUTCOffset = await getTimeByLocation(this.state.userLocation, today);
        console.log(userUTCOffset);

        if(userUTCOffset.error) {
            this.setState({
                redirect: true,
            });
            return;
        } else {
            this.setState({
                userUTCOffset: userUTCOffset.rawOffset,
                userDSTUTCOffset: userUTCOffset.dstOffset,
            }, ()=>console.log(this.state));
        }

    }

    computePracticeOffsets = async () => {
        // UTC offset
        const today = Date.now() / 1000;
        console.log('This is the current practice.');
        console.log(this.state.practice);
        const practiceUTCOffset = await getTimeByLocation({lat: this.state.practice.latitude,
            lng: this.state.practice.longitude}, today);
        console.log(practiceUTCOffset);
        if(practiceUTCOffset.error) {
            this.setState({
                redirect: true
            });
            return;
        } else {
            this.setState({
                practiceUTCOffset: practiceUTCOffset.rawOffset,
                practiceDSTUTCOffset: practiceUTCOffset.dstOffset,
            }, () => console.log(this.state));
        }
    }


    render() {
        if(this.state.redirect) {
            return (
                <Redirect to='/404' />
            )
        }
        const practiceInfo = this.state.practice;
        const address = `${practiceInfo.address}, ${practiceInfo.city}, ${practiceInfo.state} ${practiceInfo.zip}`
        // const deltaUTCOffset = this.state.userUTCOffset - this.state.practiceUTCOffset;

        // head variables
        const title = `${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Urgent Care - UrgentCare.com`;
        const description = `Information for ${practiceInfo.name} ${practiceInfo.city} ${practiceInfo.state} Urgent Care - Hours, Location, Insurance Accepted, COVID-19 Testing Service Information`;
        const keywords = `${practiceInfo.name}, urgent care, ${practiceInfo.city}, ${practiceInfo.state}`;
        let website = "";
        if(practiceInfo.website !== "") {
            const website_pattern = /^https?:\/\//;
            website = website_pattern.test(practiceInfo.website) ? practiceInfo.website :
                                                                   "https://" + practiceInfo.website;
        }

        return (
            <>
            <div className="section results" >
                <Helmet>
                    <title>{title}</title>
                    <meta name="description" content={description} />
                    <meta name="keywords" content={keywords} />
                </Helmet>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            {/*<Link to={{pathname: '/practice/', zip: practiceInfo.zip, zipOnly: this.props.zipOnly}}>*/}
                            {/*    <a className="my-5 d-block">*/}
                            {/*        <i className="fas fa-arrow-left mr-3" style={{"visibility": "hidden"}}></i>*/}
                            {/*    </a>*/}
                            {/*</Link>*/}
                            <div className="media">
                                <DateTodaySection />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="listings-box p-3 p-md-5 result-box qy-5 drop-shadow">
                                <div className="row">
                                    <div className="col-12 col-md-7">
                                        <div className="float-none float-md-left text-left d-block d-md-inline mr-3">
                                            <img src={getRandomImageFilename()} className="img-fluid rounded" alt='' />
                                        </div>
                                        <ul className="list-unstyled" style= {{overflow: "auto"}}>
                                            <li>
                                                <h5 className="mb-3">{this.state.practice.name}</h5>
                                            </li>
                                            <li className="mb-3"><i className="fas fa-map-marker-alt mr-3"></i>{address}
                                            </li>
                                                <li className="mb-0 mb-md-3"><a href="#" onClick={(e) => { e.preventDefault() }}
                                                                            className="d-inline-block sms-btn round-border-10 py-2 px-3 mb-3 mb-md-0"
                                                                            data-toggle="modal"
                                                                            data-target="#sms-modal"
                                                                            >
                                                <i className="fas fa-comment mr-3"></i>SMS Address</a></li>

                                            <li  className="mb-3"><i className="fas fa-phone-alt mr-3"></i>{practiceInfo.phone}</li>

                                            <li  className="mb-3 mail"><i className="fas fa-globe-americas mr-3"></i>
                                                <a href={website}>{ website === "" ? "Not available" : website }
                                                </a>
                                            </li>

                                        </ul>

                                        <hr className="mb-3"/>
                                        {
                                            this.props.location && CLAIM_REGEX.test(this.props.location.search) &&
                                            <a href={`https://admin.urgentcare.com/claim/${this.state.practice.practice_id}`} className="btn-cta zy-5">Claim this listing</a>
                                        }


                                        <ScheduleTable schedules={this.state.practice.schedules}
                                                       userUTCOffset={this.state.userUTCOffset}
                                                       practiceUTCOffset={this.state.practiceUTCOffset}
                                                       DSTUTCOffset={this.state.practiceDSTUTCOffset}
                                                       userDSTUTCOffset={this.state.userDSTUTCOffset}/>

                                    </div>
                                    <div className="col-12 col-md-5">
                                        <div className="map-responsive h-100 mb-5 rounded-10">
                                            <SingleLocationMapSection lat={practiceInfo.latitude} lng={practiceInfo.longitude}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="clearfix"></div>
                                <div className="row mt-4">
                                    {/* <TestingInfoCard title="COVID-19 Viral Testing"
                                                     description="This test looks for an active infection of
                                                                    SARS-CoV-2, or whether you are currently sick with Coronavirus. The method of testing varies by
                                                                    Urgent Care location."
                                                     tests={this.state.practice.covid19TestServices} /> */}
                                    <div className="col-md-6 mt-5 mb-0 mt-5 xy-md-5">
                                    <a id="glp-internal" href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=cliniclisting" target='_blank' rel="noopener noreferrer">
                                      <div className="list-box round-border-10 h-100 p-4 p-md-5">
                                          <img src="/img/ico/vial-syringe-colorful.png" alt="Lose weight with GLP-1" className="mb-4 mb-md-5 img-head" />
                                              <h3 className="mb-4 mb-md-5 mb-lg-4">Lose weight with GLP-1 medications.</h3>
                                              <p className="cus-space" style={{marginBottom: "70px"}}>Obesity is a disease that is often still associated with stigma, bias and misinformation. We are working to help you make informed decisions around obesity while making GLP-1 medications more accessible. Helping fight obesity one person at a time.</p>     
                                             <div className="home-box-footer">
                                                <button className="learn-more" style={{backgroundColor: 'green', color: 'white'}} href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=cliniclisting">Learn More</button>
                                              </div>
                                      </div>
                                      </a>
                                    </div>

                                    <div className="col-md-6 mt-5 mb-0 mt-5 xy-md-5">
                                    <a id="t2d-internal" href="https://www.t2d.com/share/t2d-provides-access-to-compounded-semaglutide-for-diabetics?utm_source=urgentcare&utm_medium=organic&utm_campaign=cliniclisting" target='_blank' rel="noopener noreferrer">
                                      <div className="list-box round-border-10 h-100 p-4 p-md-5">
                                          <img src="/img/t2d-blue.svg" alt="T2D.com" className="mb-2 mb-md-5 img-head" style={{"max-width": "none"}} />
                                              <h3 className="mb-4 mb-md-5 mb-lg-4">GLP-1s for Diabetics and Pre&#x2011;Diabetics</h3>
                                              <p className="cus-space" style={{marginBottom: "70px"}}>GLP-1 medications paired with lifestyle changes is an effective way to manage your Diabetes and Pre-Diabetes. GLP-1 medications help you lose weight and get your life in control. Start your journey today.</p>     
                                             <div className="home-box-footer">
                                                <button className="learn-more" style={{backgroundColor: 'green', color: 'white'}} href="https://www.t2d.com/share/t2d-provides-access-to-compounded-semaglutide-for-diabetics?utm_source=urgentcare&utm_medium=organic&utm_campaign=cliniclisting">Learn More</button>
                                              </div>
                                      </div>
                                      </a>
                                    </div>
                                  
                                    {/* <TestingInfoCard title="COVID-19 Antibody Testing"
                                                     description="This test looks for a past infection with SARS-CoV-2, which indicates that you have been sick but
                                                                    recovered from Coronavirus. A past exposure with positive antibodies can possibly protect against
                                                                    future infections, although this information is not fully validated."
                                                     tests={this.state.practice.antibodyTestServices} /> */}
                                    {
                                        this.state.practice.services !== "" &&
                                        <ServicesOffered title = "Services We Offer"
                                                         services ={this.state.practice.services}/>
                                    }

                                    {
                                        this.state.practice.insurance !== "" &&
                                        <Insurance title = "Insurance Carriers Accepted"
                                                   insurances={this.state.practice.insurance}/>
                                    }

                                   
                                </div>



                                {/*<About title= "About Us"*/}
                                {/*    about={this.state.practice.practice}/>*/}

                    </div>

                            {
                                this.props.match &&
                                <ModalSMS
                                    address = {this.state.practice.address}
                                    lat={practiceInfo.latitude}
                                    lng={practiceInfo.longitude}
                                    slug={this.props.match.params.slug}
                                />
                            }

                        </div>
                    </div>
                    {
                        this.state.isGeolocationDenied &&
                        <StickyGeolocationMessage message='For better geolocation experience, turn on your location permissions for the site.'/>
                    }
                </div>

            </div>


            </>

        )
    }

    // render() {
    //     return (
    //         <h1>Listings</h1>
    //     )
    // }
}

export default ListingPage
