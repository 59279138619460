import React from 'react';

function UrgentCareCentersSection(){
    return(
        <div className="section" style= {{backgroundColor:"rgba(246,248,250,2)"}}>
            <div className="container">
                <div className="row">
                    <div className="col mb-4">
                        <h2 className="text-center">Check Out Some of These Urgent Care Centers</h2>
                    </div>
                </div>
                <div className="row">
                   
                    <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="img/logo/medexpress.svg" alt='' className="img-fluid"/>
                            <title>
                                MedExpress
                            </title>
                        </a>
                    </div>

                    <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="img/logo/nextcare.svg" alt='' className="img-fluid"/>
                            <title>
                                NextCare
                            </title>
                        </a>
                    </div>
                   
                    <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="img/logo/afc.svg" alt='' className="img-fluid"/>
                            <title>
                                American Family Care
                            </title>
                        </a>
                    </div>
                   
                   <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="img/logo/concentra.svg" alt='' className="img-fluid"/>
                            <title>
                                Concentra
                            </title>
                        </a>
                    </div>
                    
                    <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="/img/logo/fastmed.svg" alt='' className="img-fluid"/>
                            <title>
                                FastMed
                            </title>
                        </a>
                    </div>
                    
                    <div className="col-6 col-md text-center mb-4 mb-md-0">
                        <a href="true" onClick={(e) => {e.preventDefault()}} className="centers-link">
                            <img src="/img/logo/citymd.svg" alt='' className="img-fluid"/>
                            <title>
                                CityMD
                            </title>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default UrgentCareCentersSection;