import React from 'react';
import {Link} from 'react-router-dom';

const Footer = () => {
    return (
        <div className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-12 mb-4 mb-md-5">
                        <div className="row no-gutters">
                            <div className="col-md-6 text-center text-md-left mb-4 mb-md-0">
                                <a href="index.html"><img src="/img/urgent-care-logo.svg" alt="urgent-care"/></a>
                            </div>
                            <div className="col-md-6 text-center text-md-right social">
                                <a href= 'https://www.facebook.com/UrgentCaredotcom' target="_blank" rel="noopener noreferrer">
                                    <i className="fab fa-facebook mr-3"></i>
                                </a>
                                <a href= 'https://twitter.com/UrgentCare' target="_blank" rel="noopener noreferrer">
                                   <i className="fab fa-twitter mr-3"></i>
                                </a>
                                {/*<Link to= ''>*/}
                                {/*    <i className="fab fa-instagram mr-3"></i>*/}
                                {/*</Link>*/}
                                {/*<Link to= ''>*/}
                                {/*    <i className="fab fa-linkedin-in"></i>*/}
                                {/*</Link>*/}
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-5 col-12">

                        <div className="row no-gutters ">
                            <div className="col-md-6 mb-4 mb-md-0">
                                <h5>Urgent Care</h5>
                                <ul className="list-unstyled">
                                    <Link to= {'/about'} >
                                        <li>About Us</li><br/>
                                    </Link>
                                    <a href='https://blog.urgentcare.com' rel="noopener noreferrer">
                                        <li>Articles</li><br/>
                                    </a>
                                    <li><a href="mailto:hello@urgentcare.com" rel="noopener noreferrer">Contact Us</a></li>
                                    <Link to= '/partnership'>
                                        <li>Partner With Us</li>
                                    </Link>
                                    
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h5>Reach us at</h5>
                                <ul className="list-unstyled">
                                    {/*<li><img src="/img/ico/ic-contact-phone.svg" className="mr-2"/>(704)*/}
                                    {/*        555-0127</li>*/}
                                    <a href="mailto:contact@urgentcare.com" rel="noopener noreferrer">
                                        <li className="mail"><i
                                            className="fas fa-envelope mr-2"></i> contact@urgentcare.com</li><br/>
                                    </a>
                                    <Link to= '/privacy-policy'>
                                        <li>Privacy Policy</li><br/>
                                    </Link>
                                    <Link to= '/terms-and-conditions'>
                                        <li>Terms of Use</li><br/>
                                    </Link>

                                </ul>
                           
                            </div>
                        </div>
                    </div>
                    {/*<div className="col-md-3 mb-4 mb-md-0 text-center">*/}
                    {/*    <Link to = '/subscription' >*/}
                    {/*        <img src="/img/news-letter.svg" className="img-fluid"/>                         */}
                    {/*    </Link>*/}
                    {/*</div>*/}
                    {/*<div className="col-md-4 text-center text-md-right">*/}
                    {/*    <Link to =''>*/}
                    {/*        <a className="btn-cta">Log In</a>*/}
                    {/*    </Link>*/}
                    {/*    */}
                    {/*</div>*/}
                </div>
            </div>
        </div>
    )
}

export default Footer;