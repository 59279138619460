import React from 'react';
import { getDistanceBetweenTwoLocationsV2, getTimeByLocation } from "../../Client";
import {
    get12HourFormatTimeString, getRandomImageFilename,
    getTimeDecimalEquivalent, isOpenTodayV2
} from "../../Helpers";

class ResultBox extends React.Component {
    state = {
        distance: null,
        practiceUTCOffset: 0,
        userUTCOffset: 0,
        practiceDSTUTCOffset: 0,
        userDSTUTCOffset: 0,
        opening: '',
        closing: '',
    }

    componentDidMount() {
        // MOBILE TAG
        // const distance = getDistanceBetweenTwoLocations((isMobile) ? this.props.zipLocation :
        //                                                              this.props.userLocation, this.props.location);

        const distance = getDistanceBetweenTwoLocationsV2((this.props.zipBased) ? this.props.zipLocation :
                                                                                this.props.userLocation, this.props.location);
        distance.then(this.setDistance);

        const d = new Date();
        console.log(d.getDay());

        console.log(this.props.schedules)

        // if (this.props.schedules.length === 0) {
        //     this.setState({
        //         schedules: {
        //             closing: "17:00:00",
        //             occurrence: { name: "Thursday" },
        //             opening: "8:00:00"
        //         },
        //     })
        // }

        // if (this.props.schedules.length >= 1) {
        //     this.setState({
        //         schedules: this.props.schedules,
        //     })
        // }


        // const practiceSchedule = this.props.schedules.map((schedule) => {

        const practice_opening = this.setPracticeOpening();
        const practice_closing = this.setPracticeClosing();

        // let practice_opening = 8;
        // let practice_closing = 17;

        // if(this.props.schedules)


        console.log(practice_opening)


        const deltaUTCOffset = ((((this.state.userUTCOffset + this.state.userDSTUTCOffset)
            - (this.state.practiceUTCOffset + this.state.practiceDSTUTCOffset))) / 60) / 60;
        const user_opening = practice_opening + deltaUTCOffset;
        const user_closing = practice_closing + deltaUTCOffset;

        this.setState({
            opening: get12HourFormatTimeString(user_opening),
            closing: get12HourFormatTimeString(user_closing),
        })
        // this.setOpening(user_opening);
        // this.setClosing(user_closing);
        
        
            // return rows here

        //     return schedule;

        // });


        // this.setState({
        //     // opening: practice_opening + 'AM',
        //     // closing: Math.floor(practice_closing) - 12 + 'PM',
        //     opening: practice_opening + 'AM',
        //     closing: practice_closing-12 + 'PM',
        // })
        // console.log(this.props.schedules);
        
    }

    setPracticeOpening = () => {
        if (this.props.schedules.length === 0){
            return 0;
        }
        else {
            return getTimeDecimalEquivalent(this.props.schedules[0].opening);
        }
    }

    setPracticeClosing = () => {
        if (this.props.schedules.length === 0){
            return 0;
        }
        else {
            return getTimeDecimalEquivalent(this.props.schedules[0].closing);
        }
    }

    setOpening = (time) => {
        if (this.isHalfHour(time)) {
            this.setState({
                opening: Math.floor(time) + ':30 AM',
            })
            return;
        }
        else {
            this.setState({
                opening: time + ':00 AM'
            })
        }
    }

    setClosing = (time) => {
        if (time>12 && this.isHalfHour(time)) {
            this.setState({
                closing: Math.floor(time - 12) + ':30 PM',
            })
            return;
        }
        if (time>12 && !this.isHalfHour(time)) {
            this.setState({
                closing: (time - 12) + ':00 PM',
            })
            return;
        }
        if (time === 12 && this.isHalfHour(time)) {
            this.setState({
                closing: Math.floor(time) + ':30 PM'
            })
        }
        else {
            this.setState({
                closing: (time) + ':00 PM'
            })
        }
    }

    isHalfHour = (time) => {
        let half_hour = time - Math.floor(time);
        return half_hour !== 0;
    } 

    setDistance = (data) => {
        // MOBILE TAG
        // this.setState({
        //     distance: data.distance,
        // }, this.fetchUTCOffsets)

        this.setState({
            distance: data.mi,
        });
    }

    // fetchUTCOffsets = () => {
    //     this.getUserUTCOffset();
    //     this.getPracticeUTCOffset();
    // }

    // getPracticeUTCOffset = () => {
    //     const today = Date.now() / 1000;
    //     const practiceUTCOffset = getTimeByLocation(this.props.location, today);
    //     practiceUTCOffset.then(this.setPracticeUTCOffset);
    // }

    // setPracticeUTCOffset = (data) => {
    //     this.setState({
    //         practiceUTCOffset: data.rawOffset,
    //         practiceDSTUTCOffset: data.dstOffset,
    //     });
    // }

    // getUserUTCOffset = () => {
    //     const today = Date.now() / 1000;
    //     const userUTCOffset = getTimeByLocation(this.props.userLocation, today);
    //     userUTCOffset.then(this.setUserUTCOffset);
    // }

    // setUserUTCOffset = (data) => {
    //     this.setState({
    //         userUTCOffset: data.rawOffset,
    //         userDSTUTCOffset: data.dstOffset,
    //     })
    // }

    computeUserOffsets = async () => {
        const today = Date.now() / 1000;
        const userUTCOffset = await getTimeByLocation(this.props.userLocation, today);
        console.log(userUTCOffset);

        if(userUTCOffset.error) {
            this.setState({
                redirect: true,
            });
            return;
        } else {
            this.setState({
                userUTCOffset: userUTCOffset.rawOffset,
                userDSTUTCOffset: userUTCOffset.dstOffset,
            }, ()=>console.log(this.state));
        }

    }

    computePracticeOffsets = async () => {
        // UTC offset
        const today = Date.now() / 1000;
        console.log('This is the current practice.');
        console.log(this.state.practice);
        const practiceUTCOffset = await getTimeByLocation(this.props.location, today);
        console.log(practiceUTCOffset);
        if(practiceUTCOffset.error) {
            this.setState({
                redirect: true
            });
            return;
        } else {
            this.setState({
                practiceUTCOffset: practiceUTCOffset.rawOffset,
                practiceDSTUTCOffset: practiceUTCOffset.dstOffset,
            }, () => console.log(this.state));
        }
    }



    render() {
        // const today = DAYS[(new Date()).getDay()];
        // const day = this.props.schedule[today];

        // const practice_opening = day ? getTimeDecimalEquivalent( day.opening) : null;
        // const practice_closing = day ? getTimeDecimalEquivalent(day.closing) : null;

        // const deltaUTCOffset = ((((this.state.userUTCOffset + this.state.userDSTUTCOffset)
        //     - (this.state.practiceUTCOffset + this.state.practiceDSTUTCOffset))) / 60) / 60;

        // const user_opening = practice_opening ? practice_opening + deltaUTCOffset : null;
        // const user_closing = practice_closing ? practice_closing + deltaUTCOffset : null;

        // MOBILE TAG
        // const status = isStillOpen( user_opening ? {opening: user_opening, closing: user_closing} : null);

        // const status = isStillOpen( user_opening ? {opening: user_opening, closing: user_closing} : null);
        const status = isOpenTodayV2(this.props.schedules);

        // eslint-disable-next-line no-unused-vars
        const isPremier = this.props.premier ? "premier" : "";
        const isActive = this.props.practice.isActive ? "premier" : "";
        const isActiveButton = this.props.practice.isActive ? "isActiveButton" : "";
        let stars = [];
        for(let i = 0; i < 5; i++) {
            stars = i < this.props.ratings ? stars.concat(
                <i className="fas fa-star rated"></i>
            ) : stars.concat(
                <i className="fas fa-star"></i>
            );
        }

        const distance = this.state.distance != null ? (this.state.distance < 1 ? "<1 mi away" :
            this.state.distance + " mi away") : "";
        const imageFile = getRandomImageFilename();

        const clinicNameSlug = this.props.name.toLowerCase().replaceAll(' ', '-')
                                                            .replaceAll('.', '')
                                                            .replaceAll(',','')
                                                            .replaceAll("'",'');
        const cityNameSlug = this.props.city.toLowerCase().replaceAll(' ', '-')
                                                          .replaceAll('.', '')
                                                          .replaceAll(',','')
                                                          .replaceAll("'",'');
        const zipSlug = this.props.practice.zip.padStart(5, '0');
        const slug = `${this.props.id}-${clinicNameSlug}-${cityNameSlug}-${zipSlug}`;
        return (
            <>
                <div className="col-lg-6 col-12 h-100">
                    {/*<a href={`/practice/${this.props.id}`} target="_blank">*/}
                    
                        <div className={"result-box p-3 p-md-3 my-3 h-100 " + isActive} onClick={()=>{this.props.isActiveClickHandler(this.props.index, this.props.id)}}>
                            <div className="float-none float-md-right text-left d-block d-md-inline">
                                {/*<img src="/img/result-img-03.png" className="img-fluid rounded"/>*/}
                                <img src={imageFile} className="img-fluid rounded" alt=""/>
                            </div>
                            <h5 className="mb-3">{this.props.name}</h5>
                            <i className="fas fa-map-marker-alt mr-3"></i>{this.props.city} · {distance}
                            {/*<div className="my-4">*/}
                            {/*    <i className="fas fa-star rated"></i>*/}
                            {/*    <i className="fas fa-star rated"></i>*/}
                            {/*    <i className="fas fa-star rated"></i>*/}
                            {/*    <i className="fas fa-star rated"></i>*/}
                            {/*    <i className="fas fa-star"></i>*/}
                            {/*</div>*/}
                            <div className="hours   mb-1">
                                <i className="fas fa-clock  mr-2"></i> {this.state.opening} - {this.state.closing}
                            </div>
                            <ul className={`mb-2 ${status.message !== "" ? "open" : "closed"}`}>
                                {/*// MOBILE TAG*/}
                                {/*<li>{status.status} · {(isMobile) ? "" : status.message}</li>*/}
                                <li>{status.status}</li>
                            </ul>
                            <a href={this.props.forClaiming?  `/practice/${slug}/?claim=True`: `/practice/${slug}`} className={"btn view-detail-button my-4 " + isActiveButton}>
                                View Details
                            </a>
                        </div>
                </div>
            </>
        )
    }
}

export default ResultBox;