import React from 'react';

class ExitDisplay extends React.Component {


    render() {
        return (
            <>      
      <div className="modal fade  modal-custom" id="exit-display"  tabndex="-1" aria-labelledby="myModalLabel" aria-hidden="true">
                     <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content"  id="myModalLabel">
                         <div className="modal-header">
                             <h3 className="modal-title text-orange" id="exampleModalLabel">Urgentcare.com can connect you to a Doctor to prescribe medication if you are eligible.</h3>
                             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                 <span aria-hidden="true">&times;</span>
                             </button>
                         </div>
                          <div className="modal-body">
                            <img src="/img/2vials.png" alt="Compounded Semaglutide and Compounded Tirzepatide" className="vials" />
                            <p className="modal-text">Once you have a prescription for either Compounded Semaglutide or Compounded Tirzepatide you can order medication that's shipped direct to your home. These compounded medications have the same active ingredient as the brand Rx like Ozempic and Mounjaro.</p>
                      
                            <div className="modal-footer">
                             <a className="btn-cta" href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=exit_banner">See If You Qualify Today</a>
                            </div>
                          </div>
                      </div>
                          
                  </div>
              </div>
              </>
        )
    }
}

export default ExitDisplay;