import React from 'react';
import {getGoogleMapsAPIAccessToken} from './../../Client';

const ModelMap = ({lat, lng}) => {
    const src = `https://www.google.com/maps/embed/v1/place?key=${getGoogleMapsAPIAccessToken()}` +
                `&q=${lat},${lng}` +
                `&zoom=16`;
    return (
        <iframe
            title = 'SMS Modal'
            width="465"
            height="150" 
            frameBorder="0" 
            style={{border:'0'}} 
            allowFullScreen 
            aria-hidden="false"
            src={src} 
            tabIndex="0">
        </iframe>
    )


}

export default ModelMap;