import React from 'react';
import MapSent from "./MapSent";
import {send_sms} from "../../Client";


class SMS extends React.Component {
    state = {
        phone: "",
        isValidPhoneNumber: true,
    }
       
    onHandleChange = (e) =>{
        this.setState({
            phone: e.target.value,
            isValidPhoneNumber: this.isValidPhoneNumber(this.stripPhoneNumber(e.target.value)),
        });
    }

    handleSubmitPhoneNumber = async() => {
        if(this.isValidPhoneNumber(this.stripPhoneNumber(this.state.phone))){
            // eslint-disable-next-line no-unused-vars
            const response = await send_sms(this.stripPhoneNumber(this.state.phone), this.props.slug);
        }
        else {
            this.setState({
                isValidPhoneNumber: false,
            })
        }
    }

    isValidPhoneNumber = (number) => {
        let phone_pattern = /^\+\d{11,12}$/;
        return phone_pattern.test(number);
    }

    stripPhoneNumber = (phone) => {
        const stripped = phone.replace("(", "")
                    .replace(")", "")
                    .replace("-", "")
                    .replace(" ", "");
        if(/^\+/.test(stripped)) {
            // console.log("Returning...");
            // console.log(stripped);
            return stripped;
        } else {
            // console.log("Returning...");
            // console.log("+1" + stripped);
            return "+1" + stripped;
        }
    }

    onSubmit = (e) => {
        e.preventDefault();
    }
    
    render() {
         return(
            <>
                <form className="mt-4" onSubmit = {this.onSubmit}>
                    <div className="form-group">
                        <label htmlFor="subEmail">SMS Number</label>
                        <input type="tel" name= "to" className="form-control drop-shadow" id="subEmail" placeholder="(123) 456-7890"
                                value={this.state.phone} onChange={this.onHandleChange} />
                    </div>
                    { !this.state.isValidPhoneNumber &&
                        <p style={{
                        color: 'red',
                            }}>
                            Invalid phone number
                        </p>}
                    <button className="btn-cta mt-1 mb-4" data-toggle="modal" data-target={this.isValidPhoneNumber(this.stripPhoneNumber(this.state.phone)) ? ".modal-success" : ""}
                        onClick={this.handleSubmitPhoneNumber}>Send Link</button>
                </form>
                             
                
                <MapSent mobileNumber={this.state.phone} />
            </>
        )
        
    }
}

export default SMS;