import React from 'react';

function LearnMoreSection(){
    return(
        <div className="section" style={{backgroundColor:"rgba(246,248,250,2)"}}>
            <div className="container">

                <div className="row">
                    <a id="glp-learn-more" className="col-md-6" href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget" target='_blank' rel="noopener noreferrer">
                        <div className="home-box round-border-10 p-40" style={{backgroundColor:"rgba(255,255,255,0.7)"}}>
                            <img src="img/ico/vial-syringe-colorful.png" alt="Lose weight with GLP-1" className="mb-3 mb-md-5" style={{padding: "2px"}} />
                            <h3 className="mb-4">Lose weight with GLP&#x2011;1 medications.</h3>
                            <p className="lead-violet mb-3">Start your journey today!</p>
                            <p className="mb-5">Obesity is a disease that is often still associated with stigma, bias and misinformation. We are working to help you make informed decisions around obesity while making GLP-1 medications more accessible. Helping fight obesity one person at a time.</p>
                            <div className="home-box-footer"><button className="learn-more" style={{backgroundColor: 'green', color: 'white'}} href="https://urgentcare.careglp.com/partner/urgentcare?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget">Learn More</button>
                            </div>
                        </div>
                    </a>

                    <div className="col-md-6 my-5 my-md-0">
                      <a id="glp-learn-more" className="col-md-6" href="https://www.t2d.com/share/t2d-provides-access-to-compounded-semaglutide-for-diabetics?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget" target='_blank' rel="noopener noreferrer">
                        <div className="home-box round-border-10 p-40" style={{backgroundColor:"rgba(255,255,255,0.7)"}}>
                            <img src="/img/t2d-blue.svg" alt="T2D.com" className="mb-2 mb-md-5 img-head" />
                            <h3 className="mb-4">GLP-1s for Diabetics and Pre&#x2011;Diabetics</h3>
                            <p className="lead-violet mb-3">GLP-1s for Diabetics and Pre&#x2011;Diabetics</p>
                            <p className="mb-5">GLP-1 medications paired with lifestyle changes is an effective way to manage your Diabetes and Pre-Diabetes. GLP-1 medications help you lose weight and get your life in control. Start your journey today.<br/><br/></p>
                           <div className="home-box-footer">
                               <button className="learn-more" style={{backgroundColor: 'green', color: 'white'}} href="https://www.t2d.com/share/t2d-provides-access-to-compounded-semaglutide-for-diabetics?utm_source=urgentcare&utm_medium=organic&utm_campaign=homepage_widget">Learn More</button>
                             </div>
                        </div>
                      </a>
                    </div>
                </div>
            </div>
        </div>

    )
        
    
}

export default LearnMoreSection;