import React from 'react'
import {Link} from 'react-router-dom';

function Partnership() {
    return (
        <div className="section partner-with-us">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-6 offset-md-1">
                        <img src="img/partner-with-us-img.png" alt="urgent-care" className="img-fluid" />
                    </div>
                    <div className="col-md-5 order-md-first">
                        <h2 className="mb-3">FOR URGENT CARE CENTERS</h2>
                        <h1>Partner with us</h1>
                        <div className="separator-line my-4"></div>
                        <p>Your responsibilities as the owner or manager of Urgent Care center are mounting and you have a lot on your mind, but how to get patients in the door shouldn’t be one of them.</p>
                        <Link to='/partnership'
                              className="border-btn my-3">
                            Partner With Us
                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partnership
