import React from 'react';

const Insurance = ({title, insurances}) => {
    const cleanedInsurance = insurances.replaceAll('\n', ',').replaceAll('\r', '').split(',').map((i) => i.trim());
    return (
        <div class="col-md-6 mt-5 mb-1 mt-md-5">
            <div class="list-box round-border-10 h-100 p-4 p-md-5">
                <img src="/img/ico/insurance-carriers.svg" alt="..." class="mb-4 mb-md-5 img-head"/>
                <h3 class="mb-4">{title}</h3>
                {(cleanedInsurance.length < 1) ?
                <p className="no-insurance"><br/><br/><br/><br/>Please call for more information.</p> :
                <p className="mb-4"></p>
                }
               <ul>
                   {cleanedInsurance.map(i =>(
                        <li key={i}>
                            - {i}
                        </li>
                   ))}
                   <br/> <br/><br/><br/>
                </ul>
            </div>
        </div>
    )
}

export default Insurance;