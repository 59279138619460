import React from 'react';
import { isMobile } from 'react-device-detect';
import { Helmet } from "react-helmet";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
// API functions
import {
    getAddressUsingCoordinates,
    getAddressUsingZIP,
    getClinicsFromZIP, getClinicsNearLocationV3, getClinicsNearZIPLocation, getClinicsNearZIPLocationV3, getCurrentPositionUsingNativeGeolocator, getNearestClinics, isZIPValid
} from "../../Client";
import StickyGeolocationMessage from "../StickyGeolocationMessage";
import CoordinateAddressWidget from "./CoordinateAddressWidget";
import DateTodaySection from "./DateTodaySection";
// Local widgets
import GoogleMapContainer from './GoogleMapContainer';
import ResultPrompt from "./ResultPrompt";
import ResultsContainer from "./ResultsContainer";
import ResultsFilterSection from "./ResultsFilterSection";
import ZIPCodeWidget from "./ZIPCodeWidget";




//*****************************************************************************************************************
//                                          MAIN APPLICATION                                                     //
//*****************************************************************************************************************
class ResultsPage extends React.Component {
    state = {
        abortController: new AbortController(),
        redirectedFromHomepage: this.props.location && this.props.location.redirectedFromHomepage ? this.props.location.redirectedFromHomepage : false,
        showUserPin: this.props.location && this.props.location.zip ? false : true,
        hasActive: null,
        page: 1,
        paginationPage: 1,
        redirectTo404: false,
        isGeolocationDenied: false,
        showModal:  true,
        firstVisit: window.sessionStorage.getItem('firstVisit'),
    }

    componentDidMount = async () => {

        // marketing campaign
        if (this.props.fromCampaign) {
            if (!this.props.campaign) {
                this.setState({
                    redirectTo404: true,
                });
                return;
            }
            
            await this.props.onFetchClinicsResults(this.props.campaign.locations);
            await this.filterClinics();
            return;
        }

        if (this.props.preview) {
            if(!this.props.practices) {
                this.setState({
                    redirectTo404: true,
                });
                return;
            }

            await this.props.onFetchClinicsResults(this.props.practices);
            await this.filterClinics();
            return;
        }

        // await getCurrentPositionUsingNativeGeolocator();
        let showUserPin = this.props.location && this.props.location.zip ? !(await isZIPValid(this.props.location.zip)) : true;
        if (this.props.fromCampaign || this.props.preview) {
            showUserPin = false;
        }
        this.setState({
            showUserPin: showUserPin,
        })
        // MOBILE TAG
        // if (isMobile) await this.fetchUserLocation();
        await this.fetchUserLocation();
        // await this.fetchUserLocation();
        if (this.props.location.zip) {
            const settings = {
                ...this.props.filter,
                covidTest: false,
                antibodyTest: false,
                xray: false,
            };
            await this.props.onUpdateFilterSettings(settings);
            const clinics = await this.searchByZip(this.props.location.zip.padStart(5, "0"));
            this.props.onZIPBasedIndicatorChange(true);

            if (this.props.zipBased) {
                if (clinics && clinics.length < 1) {
                    // get nearby zip locations

                    if (this.props.zipCoordinates.lat == null || this.props.zipCoordinates.lng == null) {
                        // default to location search

                        const params = {
                            lat: this.props.coordinates.lat,
                            long: this.props.coordinates.lng,
                        }
                        this.props.onUpdateLoadingIndicator(true);
                        const clinics = await getNearestClinics(params);
                        this.props.onUpdateLoadingIndicator(false);
                        await this.props.onFetchClinicsResults(clinics);
                        // await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
                    } else {
                        const params = {
                            lat: this.props.zipCoordinates.lat,
                            long: this.props.zipCoordinates.lng,
                        }
                        this.props.onUpdateLoadingIndicator(true);
                        const clinics = await getNearestClinics(params);
                        this.props.onUpdateLoadingIndicator(false);
                        await this.props.onFetchClinicsResults(clinics);
                        await this.props.onUpdateRedirectFromEmptyZIPBasedSearch(true);
                    }
                }
            }
        } else {
            const settings = {
                ...this.props.filter,
            };
            if (this.props.location.redirect) {
                settings['covidTest'] = true;
            }
            await this.props.onUpdateFilterSettings(settings);
            const clinics = await this.searchByLocation();
            this.props.onZIPBasedIndicatorChange(false);

            if (clinics && clinics.length < 1) {
                // search for the nearest
                const params = {
                    lat: this.props.coordinates.lat,
                    long: this.props.coordinates.lng,
                }
                this.props.onUpdateLoadingIndicator(true);
                const clinics = await getNearestClinics(params);
                this.props.onUpdateLoadingIndicator(false);
                await this.props.onFetchClinicsResults(clinics);
                await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
            }
        }

        this.filterClinics();
    }

    async componentWillUnmount() {
        // const settings = {
        //     ...this.props.filter,
        //     covidTest: false,
        //     antibodyTest: false,
        //     xray: false,
        //     radius: 20,
        // };

        // await this.props.onUpdateFilterSettings(settings);
        await this.state.abortController.abort();
        await this.setState({
            abortController: new AbortController(),
        });

        this.props.clearStore();
    }


    // User location methods
    fetchUserLocation = async () => {
        this.props.onUpdateLoadingIndicator(true);
        // const data = await getCurrentPositionUsingGoogleMapsAPI();
        const data = await getCurrentPositionUsingNativeGeolocator();
        if(data.error) {
            if(data.error.code === 1) {
                console.log('Geolocation is not enabled.');
                this.setState({
                    isGeolocationDenied: true,
                })
                this.props.onUpdateLoadingIndicator(false);
            }
            return;
        }
        this.props.onFetchUserLocationResult(data);
        const address = await this.fetchUserLocationAddress();
        this.props.onFetchUserLocationAddressResult(address);
    }

    fetchUserLocationAddress = async () => {
        this.props.onUpdateLoadingIndicator(true);
        const address_data = await getAddressUsingCoordinates(this.props.coordinates);
        this.props.onUpdateLoadingIndicator(false);

        if (address_data) {
            const compoundCode = address_data.plus_code.compound_code;
            let splitCompoundCode = compoundCode.split(",");
            splitCompoundCode.reverse();
            return `${splitCompoundCode[1]}, ${splitCompoundCode[0]}`;
        }

        return null;
    }

    searchByLocation = async () => {
        // update ZIP based indicator
        await this.props.onZIPBasedIndicatorChange(false);

        const params = {
            lat: this.props.coordinates.lat,
            long: this.props.coordinates.lng,
            radius: this.props.filter.radius,
        }

        this.props.onUpdateLoadingIndicator(true);
        // const clinics = await getClinicsNearUserLocation(params, this.state.abortController);
        let clinics = await getClinicsNearZIPLocationV3(params, this.state.abortController);
        if(clinics && clinics.length < 1) {
            clinics = await getClinicsNearLocationV3(params, this.state.abortController);
        }

        this.props.onUpdateLoadingIndicator(false);
        await this.props.onFetchClinicsResults(clinics);
        return clinics;
    }

    // ZIP methods
    handleZIPSubmitClick = async (zip) => {
        this.setState({
            showUserPin: zip ? !(await isZIPValid(zip)) : true,
            hasActive: null,
        })
        const clinics = zip != null ? await this.searchByZip(zip) :
            !this.state.isGeolocationDenied ? await this.searchByLocation() : [];
            
        // reset redirection indicators first
        this.props.onUpdateRedirectFromEmptyLocationBasedSearch(false);
        this.props.onUpdateRedirectFromEmptyZIPBasedSearch(false);

        if (this.props.zipBased) {
            if (clinics && clinics.length < 1) {
                // get nearby zip locations

                if (this.props.zipCoordinates.lat == null || this.props.zipCoordinates.lng == null) {
                    // default to location search

                    const params = {
                        lat: this.props.coordinates.lat,
                        long: this.props.coordinates.lng,
                    }
                    this.props.onUpdateLoadingIndicator(true);
                    const clinics = await getNearestClinics(params);
                    this.props.onUpdateLoadingIndicator(false);
                    await this.props.onFetchClinicsResults(clinics);
                    await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
                } else {
                    const params = {
                        lat: this.props.zipCoordinates.lat,
                        long: this.props.zipCoordinates.lng,
                    }
                    this.props.onUpdateLoadingIndicator(true);
                    const clinics = await getNearestClinics(params);
                    this.props.onUpdateLoadingIndicator(false);
                    await this.props.onFetchClinicsResults(clinics);
                    await this.props.onUpdateRedirectFromEmptyZIPBasedSearch(true);
                }
            }
        } else {
            if (clinics && clinics.length < 1) {
                // search for the nearest
                const params = {
                    lat: this.props.coordinates.lat,
                    long: this.props.coordinates.lng,
                }
                this.props.onUpdateLoadingIndicator(true);
                const clinics = await getNearestClinics(params);
                this.props.onUpdateLoadingIndicator(false);
                await this.props.onFetchClinicsResults(clinics);
                await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
            }
        }
        await this.filterClinics();
    }

    searchByZip = async (zip) => {
        // ZIP information
        this.props.onZIPChange(zip);

        // update ZIP based indicator
        this.props.onZIPBasedIndicatorChange(true);
        this.props.onUpdateLoadingIndicator(true);
        const zipAddress = await getAddressUsingZIP(zip);
        this.props.onUpdateLoadingIndicator(false);

        const addressInformation = await this.onFetchZIPAddressResult(zipAddress);
        const address = addressInformation ? addressInformation.address : "Invalid ZIP code";
        const coordinates = addressInformation ? addressInformation.coordinates : {
            lat: null,
            lng: null,
        };
        this.props.onZIPLocationChange(coordinates);
        this.props.onZIPLocationAddressChange(address);

        // Fetching clinics from ZIP code
        // Use client-side filtering
        // const clinics = await this.fetchClinicsFromZIPCode();
        const params = {
            lat: this.props.zipCoordinates.lat,
            long: this.props.zipCoordinates.lng,
            radius: this.props.filter.radius,
        }
        this.props.onUpdateLoadingIndicator(true);
        const clinics = await getClinicsNearZIPLocationV3(params, this.state.abortController);
        this.props.onUpdateLoadingIndicator(false);
        this.props.onFetchClinicsResults(clinics);
        return clinics;
    }

    fetchClinicsFromZIPCode = async () => {
        const params = {
            zip: this.props.zip,
            ...this.props.filter,
        }

        this.props.onUpdateLoadingIndicator(true);
        const clinics = await getClinicsFromZIP(params, this.state.abortController);
        this.props.onUpdateLoadingIndicator(false);

        return clinics;
    }

    fetchClinicsNearZIPCode = async () => {
        const params = {
            lat: this.props.zipCoordinates.lat,
            long: this.props.zipCoordinates.lng,
            radius: this.props.filter.radius,
        }

        this.props.onUpdateLoadingIndicator(true);
        const clinics = await getClinicsNearZIPLocation(params, this.state.abortController);
        this.props.onUpdateLoadingIndicator(false);

        return clinics;
    }

    onFetchZIPAddressResult = (data) => {
        if (data) {
            if (data.results.length > 0) {
                if (data.results[0].types && data.results[0].types[0] === "postal_code") {
                    const address = data.results[0].formatted_address;
                    return {
                        address: address,
                        coordinates: {
                            lat: data.results[0].geometry ? data.results[0].geometry.location.lat : 15,
                            lng: data.results[0].geometry ? data.results[0].geometry.location.lng : 121,
                        }
                    };
                } else {
                    return {
                        address: "ZIP code not found",
                        coordinates: {
                            lat: 15,
                            lng: 121,
                        }
                    };
                }
            }

            return null;
        }

        return null;
    }

    // Filter methods
    handleFilterChange = async (event, slider = false) => {
        const settings = {
            ...this.props.filter,
        };
        settings[event.target.name] = slider ? event.target.value : event.target.checked;

        // Redux dispatch is synchronous but it seems to be async with this call
        await this.props.onUpdateFilterSettings(settings);

        // Fetching clinics from ZIP code again after filter change
        // Use client side filtering

        // Server-side filtering
        // const clinics = await this.fetchClinicsFromZIPCode();
        // await this.props.onFetchClinicsFromZIPCodeResults(clinics);


        // Client-side filtering
        if (slider) {
            // clear indicators
            this.props.onUpdateRedirectFromEmptyLocationBasedSearch(false);
            this.props.onUpdateRedirectFromEmptyZIPBasedSearch(false);

            if (!this.props.zipBased) {
                const clinics = await this.searchByLocation();
                // this.props.onZIPBasedIndicatorChange(false);

                if (clinics && clinics.length < 1) {
                    // search for the nearest
                    const params = {
                        lat: this.props.coordinates.lat,
                        long: this.props.coordinates.lng,
                    }
                    this.props.onUpdateLoadingIndicator(true);
                    const clinics = await getNearestClinics(params);
                    this.props.onUpdateLoadingIndicator(false);
                    await this.props.onFetchClinicsResults(clinics);
                    await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
                }
            } else {
                const settings = {
                    ...this.props.filter,
                    covidTest: false,
                    antibodyTest: false,
                    xray: false,
                };
                await this.props.onUpdateFilterSettings(settings);
                const clinics = await this.searchByZip(this.props.zip.padStart(5, "0"));
                this.props.onZIPBasedIndicatorChange(true);

                if (clinics && clinics.length < 1) {
                    // get nearby zip locations

                    if (this.props.zipCoordinates.lat == null || this.props.zipCoordinates.lng == null) {
                        // default to location search

                        const params = {
                            lat: this.props.coordinates.lat,
                            long: this.props.coordinates.lng,
                        }
                        this.props.onUpdateLoadingIndicator(true);
                        const clinics = await getNearestClinics(params);
                        this.props.onUpdateLoadingIndicator(false);
                        await this.props.onFetchClinicsResults(clinics);
                        // await this.props.onUpdateRedirectFromEmptyLocationBasedSearch(true);
                    } else {
                        const params = {
                            lat: this.props.zipCoordinates.lat,
                            long: this.props.zipCoordinates.lng,
                        }
                        this.props.onUpdateLoadingIndicator(true);
                        const clinics = await getNearestClinics(params);
                        this.props.onUpdateLoadingIndicator(false);
                        await this.props.onFetchClinicsResults(clinics);
                        await this.props.onUpdateRedirectFromEmptyZIPBasedSearch(true);
                    }
                }
            }
        }
        await this.filterClinics();
    }

    // functions for page and clinic highlights

    isActiveClickHandler = (clinicIndex, clinicId) => {
        let newClinics = this.props.filteredClinics.map((clinic, index) => {
            if (clinicIndex === index) {
                let pageNumbers = [];
                for (let i = 1; i <= Math.ceil(this.props.filteredClinics.length / 6); i++) {
                    pageNumbers.push(i);
                }
                ;
                let number;
                if (index > 5) {
                    number = Math.ceil((index + 1) / 6)
                } else {
                    number = 1
                }
                let paginationPage;
                if (number > 6) {
                    paginationPage = Math.ceil((number + 1) / 7)
                } else {
                    paginationPage = 1
                }
                this.setState({
                    page: number,
                    paginationPage: paginationPage
                })
                clinic.isActive = true;
                return clinic
            } else {
                clinic.isActive = false;
                return clinic
            }
        })
        this.setState({
            hasActive: clinicIndex
        })

        this.props.onFilterClinicsResult(newClinics);
    }

    onDismissActiveMarker = () => {
        this.isActiveClickHandler(null, null);
    }

    handlePreviousPageClick = () => {
        this.setState((previousState) => {
            return {
                page: previousState.page - 1,
            }
        })
    }

    handleNextPageClick = () => {
        this.setState((previousState) => {
            return {
                page: previousState.page + 1,
            }
        })
    }

    handlePreviousPaginationClick = () => {
        if (this.state.paginationPage === 1) {
            this.setState({
                paginationPage: 1
            })
        } else {
            this.setState({
                paginationPage: this.state.paginationPage - 1
            })
        }
    }

    handleNextPaginationClick = () => {
        this.setState({
            paginationPage: this.state.paginationPage + 1
        })
    }
    handleChangePageClick = (number) => {
        this.setState({
            page: number
        });
    }

    // Result and filter methods
    // Client side filtering
    filterClinics = () => {
        let clinics = this.props.clinics;
        const {antibodyTest, covidTest, xray} = this.props.filter;
        clinics = covidTest ? this.filterClinicsWithCOVIDTestServices(clinics) : clinics;
        clinics = antibodyTest ? this.filterClinicsWithAntibodyTestServices(clinics) : clinics;
        clinics = xray ? this.filterClinicsWithXrayServices(clinics) : clinics;
        
        this.props.onFilterClinicsResult(clinics);

        let message = this.props.zipBased ? `${clinics.length} result${clinics.length > 1 ? "s" : ""}` :
            // `${clinics.length} result${clinics.length > 1 ? "s" : ""} found within ${this.props.filter.radius} mi`;
            `${clinics.length} result${clinics.length > 1 ? "s" : ""} found`;


        if (this.props.zipBased && this.props.redirectedFromEmptyZIPBasedSearch) {
            // message = `No results found from ZIP code, ${this.props.zip} within ${this.props.filter.radius} mi.` +
            // ` We are showing the nearest clinic${this.props.filteredClinics.length > 1 ? "s" : ""}.`;
            message = `${clinics.length} result${clinics.length > 1 ? "s" : ""}`;
            this.props.onUpdateRedirectFromEmptyZIPBasedSearch(false);
        }

        if (this.props.zipBased && this.props.filteredClinics.length < 1 && !(this.props.redirectedFromEmptyZIPBasedSearch)) {
            message = `No results found from ZIP code, ${this.props.zip}, with the set filter settings.`;
        }

        if (!(this.props.zipBased) && this.props.redirectedFromEmptyLocationBasedSearch) {
            message = `No results found within ${this.props.filter.radius} mi with the filter settings.` +
                (this.props.filteredClinics.length > 0 ?
                    ` We are showing ${this.props.filteredClinics.length} clinic${this.props.filteredClinics.length > 1 ? "s" : ""} nearest to you.` :
                    " Adjust the filter settings to show clinics near you.");
            // this.props.onUpdateRedirectFromEmptyLocationBasedSearch(false);
        }

        if (this.props.displayAddress === "ZIP code not found" || this.props.displayAddress === "Invalid ZIP code") {
            message = `Invalid ZIP code, ${this.props.zip}. ` +
                // ` We are showing ${this.props.filteredClinics.length} clinic${this.props.filteredClinics.length > 1 ? "s" : ""} nearest to you.`;
                (this.props.filteredClinics.length > 0 ?
                    ` We are showing ${this.props.filteredClinics.length} clinic${this.props.filteredClinics.length > 1 ? "s" : ""} nearest to you.` :
                    " Adjust the filter settings to show clinics near you.");
        }

        if(this.props.fromCampaign && this.props.campaign && this.props.filteredClinics.length < 1) {
            message = 'No results based on filters. Adjust your filter settings.';
        }

        if(this.props.preview && this.props.filteredClinics.length < 1) {
            message = 'No results based on filters. Adjust your filter settings.';
        }

        this.props.onUpdateResultsPrompt(message)
    }

    filterClinicsWithXrayServices = (clinics) => {
        return clinics.filter((clinic) => {
            return clinic.xray;
        });
    }

    filterClinicsWithCOVIDTestServices = (clinics) => {
        return clinics.filter((clinic) => {
            return clinic.covid19TestServices.length > 0;
        });
    }

    filterClinicsWithAntibodyTestServices = (clinics) => {
        return clinics.filter((clinic) => {
            return clinic.antibodyTestServices.length > 0;
        })
    }


    render() {
        // MOBILE TAG
        const redirect = !this.state.redirectedFromHomepage && !isMobile && !this.props.fromCampaign && !this.props.preview;
        // eslint-disable-next-line no-unused-vars
        let cities = [];
        if(this.props.fromCampaign && this.props.campaign) {
            cities = this.props.campaign.locations.map((location) => location.city);
        }

        if(this.props.preview && this.props.practices) {
            cities = this.props.practices.map((location) => location.city);
        }
        cities = [...new Set(cities)];
        return (
            <>
                {
                    this.state.redirectTo404 && <Redirect to='/404'/>
                }
                {
                    this.state.isGeolocationDenied &&
                    <StickyGeolocationMessage message='To use the geolocation service, turn on your location permissions for the site.'/>
                }
                {this.props.loading &&
                <div className='loading-overlay'>
                    <div className='message'>
                        <i className="fa fa-spinner loading-icon" aria-hidden="true"></i>
                        <p>Loading...</p>
                    </div>
                </div>
                }
                {
                    redirect &&
                    <Redirect to={{
                        pathname: "/",
                    }}/>
                }
                <Helmet>
                    <title>UrgentCare.com: Find Your Local Urgent Care Center, Get Directions, Hours of Operation and
                        COVID-19 Testing - Search</title>
                </Helmet>

                <div className="section results">

                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-md-7">
                                {/*<ReactReduxContext.Consumer>*/}
                                {/*    {({ store }) => {*/}
                                {/*        console.log(store.getState());*/}
                                {/*    }}*/}
                                {/*</ReactReduxContext.Consumer>*/}
                                <DateTodaySection/>
                                {
                                    this.props.location &&
                                    <ZIPCodeWidget onZIPSubmitClick={this.handleZIPSubmitClick}
                                                   initialZIP={this.props.location.zip}
                                                    isGeolocationDenied={this.state.isGeolocationDenied}/>
                                }

                                {
                                    this.props.fromCampaign && this.props.campaign &&
                                    <div className="zip-box mb-4">
                                        <div className="float-left mr-3">
                                            <h5>ORGANIZATION NAME:</h5>
                                            <span>{this.props.campaign.organization_name}</span>
                                        </div>
                                    </div>
                                }

                                {
                                    this.props.preview && this.props.campaignParameters.organizationName && this.props.campaignParameters.type === "ORGANIZATION_PLAN" &&
                                    <div className="zip-box mb-4">
                                        <div className="float-left mr-3">
                                            <h5>ORGANIZATION NAME:</h5>
                                            <span>{decodeURIComponent(this.props.campaignParameters.organizationName)}</span>
                                            {/*<div className="w-100 font-weight-bold pb-4  mx-0 mt-3">*/}
                                            {/*    {*/}
                                            {/*        cities.map(city => {*/}
                                            {/*            return <button className="btn-solid-orange-sm rounded-20 px-2 py-1" style={{*/}
                                            {/*                margin: "0px 3px",*/}
                                            {/*            }}>{city}*/}
                                            {/*            </button>*/}
                                            {/*        })*/}
                                            {/*    }*/}

                                            {/*</div>*/}
                                        </div>
                                    </div>
                                }

                                {
                                    this.props.preview && this.props.campaignParameters.location_name && this.props.campaignParameters.type === "GEOLOCATION_PLAN" &&
                                    <div className="zip-box mb-4">
                                        <div className="float-left mr-3">
                                            <h5>LOCATION/GEOGRAPHY</h5>
                                            <span>{decodeURIComponent(this.props.campaignParameters.location_name)}</span>
                                        </div>
                                    </div>
                                }
                                <ResultPrompt message={this.props.resultsPrompt}/>
                                <CoordinateAddressWidget
                                    address={this.props.displayAddress}
                                />

                                <ResultsFilterSection onFilterChange={this.handleFilterChange}
                                                      sliderVisible={!this.props.zipBased}
                                                      initialSettings={this.props.filter}
                                                      fromCampaign={this.props.fromCampaign}
                                                      fromPreview={this.props.preview}/>
                                <ResultsContainer clinics={this.props.filteredClinics}
                                                  key={new Date()}
                                                  userLocationCoordinates={this.props.coordinates}
                                                  zipLocationCoordinates={this.props.zipCoordinates}
                                                  zipBased={this.props.zipBased}
                                                  isActiveClickHandler={this.isActiveClickHandler}
                                                  page={this.state.page}
                                                  paginationPage={this.state.paginationPage}
                                                  handleNextPaginationClick={this.handleNextPaginationClick}
                                                  handlePreviousPaginationClick={this.handlePreviousPaginationClick}
                                                  handleNextPageClick={this.handleNextPageClick}
                                                  handlePreviousPageClick={this.handlePreviousPageClick}
                                                  handleChangePageClick={this.handleChangePageClick}/>
                            </div>

                            <div className="col-12 col-md-5">
                                <div className="map-responsive h-100" id="map_container">
                                    <GoogleMapContainer locations={this.props.filteredClinics}
                                                        lat={this.props.coordinates.lat}
                                                        lng={this.props.coordinates.lng}
                                                        showUserPin={(this.props.fromCampaign || this.props.preview) ? false : this.state.showUserPin}
                                                        isActiveClickHandler={this.isActiveClickHandler}
                                                        onDismissActiveMarker={this.onDismissActiveMarker}
                                                        hasActive={this.state.hasActive}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </>
        );
    }
}

// Redux setup

const mapStateToProps = (state) => {
    return {
        coordinates: state.userLocation,
        zipCoordinates: state.zipLocation,
        zip: state.zip,
        address: state.zipLocationAddress,
        clinics: state.clinics,
        filter: state.filter,
        filteredClinics: state.filteredClinics,
        zipBased: state.zipBased,
        redirectedFromEmptyZIPBasedSearch: state.redirectedFromEmptyZIPBasedSearch,
        redirectedFromEmptyLocationBasedSearch: state.redirectedFromEmptyLocationBasedSearch,
        displayAddress: state.zipBased ? state.zipLocationAddress : state.userLocationAddress,
        resultsPrompt: state.resultsPrompt,
        loading: state.loading,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        //============================================================//
        //                   LOCATION METHODS                         //
        //============================================================//

        onFetchUserLocationAddressResult: (address) => {
            dispatch({
                type: "UPDATE_USER_LOCATION_ADDRESS",
                address: address ? address.trim() : "No address for user location",
            });
        },

        //============================================================//
        //                      ZIP METHODS                           //
        //============================================================//

        onZIPChange: (zip) => {
            dispatch({
                type: "UPDATE_ZIP",
                zip: zip,
            });
        },

        onZIPLocationAddressChange: (address) => {
            dispatch({
                type: "UPDATE_ZIP_LOCATION_ADDRESS",
                address: address.trim(),
            });
        },

        onZIPLocationChange: ({lat, lng}) => {
            dispatch({
                type: "UPDATE_ZIP_LOCATION",
                lat: lat,
                lng: lng,
            });
        },

        onFetchUserLocationResult: (data) => {
            dispatch({
                type: "UPDATE_USER_LOCATION",
                lat: data.location ? data.location.lat : 0,
                lng: data.location ? data.location.lng : 0,
                // lat: 26.5182578,
                // lng: -80.0889547,
            });
        },

        onFetchClinicsResults: (clinics) => {
            let assignedWithActive;

            //adds isActive value per clinic
            if (clinics) {
                assignedWithActive = clinics.map((clinic) => {
                    let details = {"isActive": false}
                    Object.assign(clinic, details)
                    return clinic
                });

            } else {
                assignedWithActive = [];
            }

            dispatch({
                type: "UPDATE_CLINICS",
                clinics: assignedWithActive,
            });
        },

        // Filter methods

        onUpdateFilterSettings: (settings) => {
            dispatch({
                type: "UPDATE_FILTER_SETTINGS",
                settings: settings,
            });
        },

        onFilterClinicsResult: (clinics) => {
            dispatch({
                type: "UPDATE_FILTERED_CLINICS",
                clinics: clinics,
            });
        },

        // indicators
        onZIPBasedIndicatorChange: (isZIPBased) => {
            dispatch({
                type: "UPDATE_SEARCH_INDICATOR",
                zipBased: isZIPBased,
            });
        },

        // message
        onUpdateResultsPrompt: (message) => {
            dispatch({
                type: "UPDATE_RESULTS_PROMPT",
                message: message,
            });
        },

        // redirects
        onUpdateRedirectFromEmptyZIPBasedSearch: (status) => {
            dispatch({
                type: "UPDATE_REDIRECT_FROM_EMPTY_ZIP_SEARCH",
                status: status,
            });
        },

        onUpdateRedirectFromEmptyLocationBasedSearch: (status) => {
            dispatch({
                type: "UPDATE_REDIRECT_FROM_EMPTY_LOCATION_SEARCH",
                status: status,
            });
        },

        // loading

        onUpdateLoadingIndicator: (status) => {
            dispatch({
                type: "UPDATE_LOADING_INDICATOR",
                status: status,
            })
        },

        // page methods
        clearStore: () => {
            dispatch({
                type: "CLEAR_STORE",
            });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsPage);
